<template>
<div class="prestationCardContainer">
  <router-link
    :to="{ name: 'prestationDetail', params: { herokuExternalId: prestation.herokuExternalId }}"
    class="prestationCard"
    :class="{'updatedFromNotif': prestation.updatedFromNotif}">
    <PrestationCardContent :prestation="prestation" />
  </router-link>
  <PrestationCardButtons v-if="!hasBeenAnswered && !isQualified && !isOnBoard && !isUserTransporterOrConsultant" :prestation="prestation" @prestationEdited="prestationEdited" />
</div>
</template>

<script>
import PrestationCardButtons from '@/components/Prestations/Search/PrestationCardButtons'
import PrestationCardContent from '@/components/Prestations/Search/PrestationCardContent'
import userService from '@/services/userService'

export default {
  name: 'PrestationCard',
  props: { prestation: Object },
  data () {
    return {
      hasBeenAnswered: !!this.prestation.state,
      isQualified: this.prestation.statusTN !== 'todo',
      isUserTransporterOrConsultant: this.$store.getters.userRole === "TRANSPORTER" || this.$store.getters.userRole === "CONSULTANT"
    }
  },
  mounted () {
  },
  computed: {
    isOnBoard() {
      return userService.isOnBoardCrew()
    }
  },
  watch: {
    prestation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler (val, oldVal) {
        this.hasBeenAnswered = !!this.prestation.state
        this.isQualified = this.prestation.statusTN !== 'todo'
      }
    }
  },
  methods: {
    prestationEdited: function (newPrestaVal) {
      this.hasBeenAnswered = true
      Object.assign(this.prestation, newPrestaVal)
      this.isQualified = this.prestation.statusTN !== 'todo'
      this.isWarned = !!this.prestation.warningDatetime
      for (let i in this.prestation.Parcour.Prestations) {
        if (this.prestation.Parcour.Prestations[i].herokuExternalId === this.prestation.herokuExternalId) {
          Object.assign(this.prestation.Parcour.Prestations[i], newPrestaVal)
        }
      }
    }
  },
  components: { PrestationCardContent, PrestationCardButtons }
}
</script>
