<template>
  <div id="stationList">
    <div class="socaContent">
      <div id="stationListHeader">
        <div class="grid-x grid-margin-x">
          <div class="cell large-5 small-5 pageTitleLight">
            {{ $t('stations.list.file')}}
          </div>
          <div v-if="canExportInCsv" class="cell large-7 small-7 downloadCSV">
            <button @click="downloadAllsCsv">
                <svgicon icon="download" class="download-icon" />
                <span class="button-text">{{$t('common.download.allStations')}}</span>
            </button>
            <button @click="downloadAsCsv()" v-if="haveStations">
                <svgicon icon="download" class="download-icon" />
                <span class="button-text">{{$t('common.download.myStations')}}</span>
            </button>
          </div>
        </div>
        <SearchStationField @searchResults="searchStation" />
      </div>
      <div class="stationListMain">
          <div v-if="stations">
            <div v-for="station in stations" :key="station.codeUic">
              <StationCard :station="station" />
            </div>
          </div>
          <div v-if="history && (!stations || stations.length === 0)" class="stationListHistory">
            <div class="stationListHistoryTitle">{{ $t('stations.historique')}}</div>
            <div v-for="station in history" :key="station.codeUic">
              <StationCard :station="station" />
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import StationCard from '@/components/Stations/Card'
import SearchStationField from '@/components/Common/SearchStationField'
import commonService from '@/services/common'

export default {
  name: 'Station',
  data: function () {
    return {
      stations: null,
      history: null,
      search: null
    }
  },
  mounted () {
    this.history = this._.cloneDeep(this.$store.getters.historyStations).reverse()
  },
  methods: {
    searchStation (searchStations) {
      this.stations = searchStations
    },
    downloadAsCsv (codeUic) {
      if (!codeUic) {
        let stations = this.$store.getters.selectedStations.length > 0 ? this.$store.getters.selectedStations : this.$store.getters.possibleStations
        codeUic = stations.map(s => s.codeUic)
      }
      return this.$backendConnector.getStationsCsv(codeUic)
        .then(result => {
          commonService.downloadBlob(result.data, 'export_stations.csv')
        })
        .catch(err => console.log(err))
    },
    downloadAllsCsv () {
      return this.downloadAsCsv('all')
    }
  },
  computed: {
    canExportInCsv: function () {
      return this.$perms.isUserAllowed('stations.export')
    },
    haveStations: function () {
      return this.$store.getters.selectedStations.length > 0 || this.$store.getters.possibleStations.length > 0
    }
  },
  components: { StationCard, SearchStationField }
}
</script>
