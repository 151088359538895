<template>
  <socamodal
    class="modalStationContainer"
    :title="$t('prestation.station.information')"
    @close="closeStation"
    @submit="validate">
    <template v-slot:header>
      <div class="modalHeaderInfo hide-for-small-only">{{$t('common.field.mandatoryFields')}}</div>
    </template>
    <div>
      <div class="stationBlock">
        <div class="stationSearchSelect" v-if="canChangeStation">
          <Multiselect v-model="selectedStation" :options="stationsList"
          :searchable="true" :internal-search="false" :options-limit="100"
          @search-change="asyncStationSearch" @input="selectStation"
          label="name" track-by="codeUic" :placeholder="`${$t('prestation.station.typeToSearch') }`"
          deselectLabel="" selectLabel="" selectedLabel="">
            <template slot="placeholder">{{$t('prestation.station.chooseStation') }}</template>
            <template slot="noOptions">{{$t('prestation.station.searchNoResults') }}</template>
            <template slot="noResult">{{$t('prestation.station.searchNoResults') }}</template>
            <template slot="caret"><svgicon icon="arrow" class="caret" /></template>
            <template slot="option" slot-scope="props">
              <div class="stationOption">
                <span class="stationOptionName">{{ props.option.name }}</span>
                <span v-if="props.option.fromPiv && (props.option.arrivalTime || props.option.departureTime )" class="pivInfo">
                  <span v-if="props.option.arrivalTime">{{ props.option.arrivalTime | hour }}</span>
                  <svgicon icon="train" />
                  <span v-if="props.option.departureTime">{{ props.option.departureTime | hour }}</span>
                </span>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="value">
              <span>{{$t('prestation.station.stationOfX', {station: value.option.name}) }}</span>
            </template>
          </Multiselect>
          <small v-if="validation.hasError('stationPresta.codeUic')" class="error">{{ $t('error.field.mandatory') }}</small>
        </div>
        <div class="stationBlockName" v-if="selectedStation && !canChangeStation">{{$t('prestation.station.stationOfX', {station: selectedStation.name}) }}</div>
        <br>
      </div>
      <div class="content-block grid-x grid-margin-x">
        <div class="cell small-12 medium-6">
          <StationInfo :station="selectedStation"></StationInfo>
        </div>
        <div class="cell small-12 medium-6">
          <div class="formElement stationMention">
            <label>{{$t('prestation.station.mention') }} *</label>
            <select v-model="stationPresta.mention" @change="onMentionChange">
              <option v-for="mention in mentions" :value="mention.sigle" :key="mention.sigle">{{mention.label}}</option>
            </select>
            <small v-if="validation.hasError('stationPresta.mention')" class="error">{{ $t('error.field.mandatory') }}</small>
          </div>

          <div class="formElement stationDatePrestation">
            <label>{{$t('common.date') }} *</label>
            <Datepicker :available-dates="availableDates" v-model="stationPresta.datePrestation"></Datepicker>
            <small v-if="validation.hasError('stationPresta.datePrestation')" class="error">{{ $t('error.field.mandatory') }}</small>
          </div>
          <div class="formElement stationDatePrestationHour">
            <label>{{$t('common.hour') }} *</label>
            <Timepicker v-model="stationPresta.datePrestationHour" />
            <small v-if="validation.hasError('stationPresta.datePrestationHour')" class="error">{{ $t('error.field.mandatory') }}</small>
          </div>

          <div class="formElement stationManager" v-if="isFirstPrestaForm && canSeeManager">
            <label>{{$t('prestation.station.manager') }}</label>
              <input type="text"
                :placeholder="`${$t('prestation.station.manager') }`"
                v-model="stationPresta.manager"
              />
          </div>
          <div class="formElement stationPresentationTime" v-if="isFirstPrestaForm && !noPresentationTime && !isOnBoard">
            <label>{{$t('prestation.station.presentationTime') }}*</label>
            <Timepicker v-model="stationPresta.presentationTimeHour" />
            <small v-if="validation.hasError('stationPresta.presentationTimeHour')" class="error">{{ $t('error.field.mandatory') }}</small>
          </div>

          <div class="formElement stationCommentary" v-if="canEditStationComment || canEditTransporterComment" >
            <label>{{ canEditStationComment ? $t('prestation.station.commentary') : $t('prestation.transporter.commentary') }}</label>
            <textarea rows="5" cols="33"
              :placeholder="`${$t('prestation.station.commentaryplaceholder') }`"
              v-model="stationPresta.commentary" v-if="canEditStationComment"
            ></textarea>
            <textarea rows="5" cols="33"
              :placeholder="`${$t('prestation.station.commentaryplaceholder') }`"
              v-model="stationPresta.commentaryTransporter" v-if="canEditTransporterComment && !canEditStationComment"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="mandatoryElementInfo show-for-small-only">{{$t('common.field.mandatoryFields')}}</div>
  </socamodal>
</template>

<script>
import moment from 'moment'
import common from '@/services/common'
import pivService from '@/services/piv'
import userService from '@/services/userService'
import StationInfo from '@/components/Stations/Info'
import Datepicker from '@/components/Common/Datepicker'
import Timepicker from '@/components/Common/Timepicker'
import Multiselect from 'vue-multiselect/src/Multiselect'
import SimpleVueValidation from 'simple-vue-validator'
import { filterStations } from '@/services/stationService'

const Validator = SimpleVueValidation.Validator

export default {
  name: 'StationPrestaForm',
  props: { parentStationData: Object, formData: Object, segmentsIndex: Number, segmentPart: String, journey: Object },
  data () {
    return {
      mentions: null,
      selectedStation: null,
      stationPresta: {
        datePrestation: new Date(),
        datePrestationHour: '',
        manager: null,
        mention: null,
        commentary: null,
        commentaryTransporter: null,
        presentationTime: null,
        presentationTimeHour: '',
        name: null,
        codeUic: null,
        sfidStation: null
      },
      availableDates: {
        start: null,
        end: null
      },
      stationsSearchResult: [],
      stationsFromPiv: [],
      canEditStationComment: this.$perms.isUserAllowed('presta.comment.station'),
      canEditTransporterComment: this.$perms.isUserAllowed('presta.comment.transporter'),
      searchStationCallId: 0,
      selectedStationUpdated: null,
      activeAlertOnly: 'activeAlert'
    }
  },
  mounted () {
    if (this.$store.getters.isOnBoardArrival) {
      this.stationPresta.mention = this.$sncfRefs.getOnBoardMentions()[1].sigle
      this.mentions = this.$sncfRefs.getOnBoardMentions()
    } else {
      this.mentions = this.isOnBoard ? this.$sncfRefs.getOnBoardMentions() : this.$sncfRefs.getMentions()
    }
    if (this.formData) {
      this.stationPresta = this._.assign(this.stationPresta, this.formData)
      this.stationPresta.datePrestation = new Date(this.stationPresta.datePrestation)
    }
    if (this.parentStationData) {
      this.selectStation(this.parentStationData)
    }
    this.setAvailableDates()
    if (this.cantHavePrestation) {
      this.stationPresta.mention = this.$sncfRefs.getMentionsByTechnicalName().none.sigle
      this.mentions = [this.$sncfRefs.getMentionsByTechnicalName().none]
    }
    // init stations from piv
    if (this.journey && this.journey.nextStops) {
      let self = this
      pivService.fetchPivStationsFromStops(this.journey.nextStops)
        .then(function (stationsWithPiv) { self.stationsFromPiv = stationsWithPiv })
    }
  },
  methods: {
    closeStation () {
      this.$emit('closeStation')
    },
    validate () {
      this.stationPresta.datePrestation = common.setHoursMinute(this.stationPresta.datePrestationHour, this.stationPresta.datePrestation)
      this.stationPresta.presentationTime = this.stationPresta.presentationTimeHour
      if (this.isFirstPrestaForm === false &&
          this.stationPresta.mention === this.$sncfRefs.getMentionsByTechnicalName().none.sigle) { // not first presta and N
        this.stationPresta.status = 'Non réalisée'
        this.stationPresta.statusTN = 'notDone'
      } else {
        this.stationPresta.status = 'À réaliser'
        this.stationPresta.statusTN = 'todo'
      }
      const self = this
      this.$validate().then(function (success) {
        if (success) {
          self.stationPresta.garePresta = self.selectedStationUpdated ?? self.selectedStation
          self.$emit('saveStation', self.stationPresta)
          self.closeStation()
        }
      })
    },
    setAvailableDates () {
      if (this.journey && this.journey.datePrestation) {
        this.availableDates.start = new Date(this.journey.datePrestation)
      } else {
        this.availableDates.start = new Date()
      }

      if (this.journey && this.journey.datePrestation) {
        this.availableDates.end = moment(new Date(this.journey.datePrestation)).add('1', 'days').toDate()
      } else if (!this.$perms.isUserAllowed('presta.creation.date.end.any')) {
        this.availableDates.end = moment().add('1', 'days').toDate()
      }
    },
    async selectStation(valSelected) {
      try {
        const hasSelectedStationFromPiv = this.stationsFromPiv.filter(station => station.codeUic === valSelected.codeUic)
        if (hasSelectedStationFromPiv.length > 0) {
          await this.$backendConnector.getGareByUic(hasSelectedStationFromPiv[0].codeUic, this.activeAlertOnly)
            .then(result => {
              valSelected = {
                fromPiv: true,
                arrivalTime: valSelected.arrivalTime,
                ...result
              }
            })
            .catch(err => console.error('Failed to fetch station details: ', err))
        }
        if (valSelected !== null && !valSelected?.equipment) {
          this.selectedStationUpdated = await this.getEquipments(valSelected)
          valSelected = this.selectedStationUpdated
        }

        if (valSelected !== null) {
          this.selectedStation = valSelected
          this.stationPresta.codeUic = valSelected.codeUic
          this.stationPresta.sfidStation = valSelected.sfid
          if (valSelected.fromPiv) {
            this.stationPresta.datePrestation = moment(valSelected.arrivalTime).toDate()
            if (this.isOnBoard) {
              const nextStations = this.$store.getters.nextStations;

              if (nextStations.length) {
                const nextStation = nextStations.find(nextpassage => nextpassage.codeUic === valSelected.codeUic);

                if (nextStation?.arrivalTime) {
                  const arrivalTime = moment(nextStation.arrivalTime).format('HH:mm');
                  this.stationPresta.datePrestationHour = arrivalTime;
                }
              }
            } else {
              this.stationPresta.datePrestationHour = moment(valSelected.arrivalTime).format('HH:mm')
            }
          }
          if (this.cantHavePrestation) {
            this.stationPresta.mention = this.$sncfRefs.getMentionsByTechnicalName().none.sigle
            this.mentions = [this.$sncfRefs.getMentionsByTechnicalName().none]
          } else {
            this.mentions = this.isOnBoard ? this.$sncfRefs.getOnBoardMentions() : this.$sncfRefs.getMentions()
          }
        } else {
          this.stationPresta.codeUic = null
          this.stationPresta.sfidStation = null
        }
      } catch (err) {
        console.log(err)
      }
    },
    asyncStationSearch (query) {
      if (!query.length) {
        this.stationsSearchResult = []
        return
      }

      if (this.isOnBoard) {
        this.stationsSearchResult = filterStations(this.stationsList, query)
        return
      }

      this.searchStationCallId++
      let self = this
      let currentSearchStationCallId = this.searchStationCallId
      this.$backendConnector.searchStations(query, this.activeAlertOnly)
        .then(function (results) {
          if (self.searchStationCallId !== currentSearchStationCallId) { return }
          self.stationsSearchResult = results.map(v => ({ ...v, fromPiv: false }))
        })
        .catch(err => console.log(err))
    },
    async getEquipments(station) {
      try {
        const results = await this.$backendConnector.getEquipments(station, this.$sncfRefs.isActiveFeatureByCode("ID_REF_GARE"));
        return results;
      } catch (err) {
        console.log(err);

        return station; // Return station as a default value
      }
    },
    onMentionChange () {
      if (this.stationPresta.mention === this.$sncfRefs.getMentionsByTechnicalName().arrival.sigle) {
        this.stationPresta.presentationTimeHour = null
      } else if (this.stationPresta.presentationTimeHour === null) {
        this.stationPresta.presentationTimeHour = moment().format('HH:mm')
      }
    }
  },
  watch: {
    // Whenever a station is selected in the Multiselect, update the value of this.selectedStation
    selectedStation(newSelectedGare, oldSelectedGare) {
      this.selectedStation = newSelectedGare
    }
  },
  computed: {
    stationsList: function () {
      if (this.stationsSearchResult.length > 0) {
        return this.stationsSearchResult
      }
      if (this.isOnBoard) {
        return this.$store.getters.possibleStations
      }
      if (this.stationsFromPiv) {
        return this.stationsFromPiv
      }
      return []
    },
    canChangeStation: function () {
      return !(this.isFirstPrestaForm)
    },
    noPresentationTime: function () {
      return this.$perms.isUserAllowed('presta.creation.presentationTime.not') || this.stationPresta.mention === this.$sncfRefs.getMentionsByTechnicalName().arrival.sigle
    },
    canSeeManager: function () {
      return this.$perms.isUserAllowed('presta.canSeeManager')
    },
    isFirstPrestaForm: function () {
      return this.segmentPart === 'from' && this.segmentsIndex === 0
    },
    cantHavePrestation: function () {
      return (this.journey && this.journey.transportMode === 'Taxi' && this.segmentPart === 'to') || ( // Arrivée Taxi
        this.selectedStation && (
          (!this.selectedStation.soca && !this.isOnBoard) || // gare non soca
          (this.$perms.isUserAllowed('presta.creation.garanteed.only') && !this.selectedStation.garanteed) || // transporteur et gare non garanti
          (!this.$perms.isUserAllowed('presta.creation.garanteed.only') && !this.selectedStation.spontaneous && !this.isOnBoard) // non transporteur et gare non spontanée
        )
      )
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    }
  },
  validators: {
    'stationPresta.codeUic': function (value) {
      return Validator.value(value).required()
    },
    'stationPresta.mention': function (value) {
      return Validator.value(value).required()
    },
    'stationPresta.datePrestation': function (value) {
      if (this.stationPresta.mention !== this.$sncfRefs.getMentionsByTechnicalName().none.sigle) {
        return Validator.value(value).required()
      }
    },
    'stationPresta.datePrestationHour': function (value) {
      if (this.stationPresta.mention !== this.$sncfRefs.getMentionsByTechnicalName().none.sigle) {
        return Validator.value(value).required()
      }
    },
    'stationPresta.presentationTimeHour': function (value) {
      if (!this.noPresentationTime && !this.isOnBoard) {
        return this.isFirstPrestaForm && Validator.value(value).required()
      }
    }
  },
  filters: {
    hour: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  components: { StationInfo, Datepicker, Timepicker, Multiselect }
}
</script>
