<template>
  <div class="prestationPage">
    <div class="socaContent">
      <svgicon v-if="isLoading && !loadError" class="loadingAnimation" icon="loader" />
      <div v-if="isLoading && loadError">{{ loadError }}</div>
      <div v-if="!isLoading" class="grid-x grid-margin-x">
        <div class="cell large-8 small-12 print-fullwidth">
          <div class="prestationPageHeader">
            <div class="printButton button-theme-round show-for-large" @click="print">
              <svgicon icon="print" />
              <span>{{ $t("prestation.print") }}</span>
            </div>
            <div class="pageTitleLight">{{ $t("prestation.file") }}</div>
          </div>
          <div class="prestationPageNotifications" v-if="(Object.keys(orderedAlerts).length > 0 || isWarned) && !isOnBoard">
            <div class="prestationPageNotificationsTitle">{{ $tc("prestation.notifications", nbOrderedAlerts) }}</div>
            <div v-for="(alertItems, alertType) in orderedAlerts" :key="alertType">
              <div class="accordionContainer" v-if="alertItems.length > 1">
                <ul class="accordion" data-accordion data-allow-all-closed="true">
                  <li class="accordion-item" data-accordion-item>
                    <a href="#" class="accordion-title whiteTitle" :class="alertType">
                      {{ $t("common." + alertType) }} ({{ alertItems.length }})
                      <svgicon icon="arrow" class="arrow white" />
                    </a>
                    <div class="accordion-content" data-tab-content>
                      <div
                        v-for="(p, idxNotif) in alertItems"
                        :key="idxNotif"
                        :class="{
                          'alertBox prestationState': true,
                          wait: alertType === 'waiting',
                          success: alertType === 'accepted',
                          alert: alertType === 'refused',
                        }"
                      >
                        <span v-if="alertType === 'accepted'">{{ $t("prestation.accepted", { name: p.garePresta.name, mention: p.mention }) }}</span>
                        <span v-if="alertType === 'refused'">{{ $t("prestation.refused", { name: p.garePresta.name, mention: p.mention }) }} - {{ $t("prestation.reason." + p.reason) }}</span>
                        <span v-if="alertType === 'waiting'">{{ $t("prestation.waiting", { name: p.garePresta.name, mention: p.mention }) }}</span>
                      </div>
                      <div v-if="isWarned" class="alertBox prestationState userWarned">{{ $t("prestation.userWarned") }}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-else>
                <div
                  v-for="(p, idxNotif) in alertItems"
                  :key="idxNotif"
                  :class="{
                    'alertBox prestationState': true,
                    wait: alertType === 'waiting',
                    success: alertType === 'accepted',
                    alert: alertType === 'refused',
                  }"
                >
                  <span v-if="alertType === 'accepted'">{{ $t("prestation.accepted", { name: p.garePresta.name, mention: p.mention }) }}</span>
                  <span v-if="alertType === 'refused'">{{ $t("prestation.refused", { name: p.garePresta.name, mention: p.mention }) }} - {{ $t("prestation.reason." + p.reason) }}</span>
                  <span v-if="alertType === 'waiting'">{{ $t("prestation.waiting", { name: p.garePresta.name, mention: p.mention }) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="prestationPageNotifications" v-if="operationInProgress">
            <div class="prestationPageNotificationsTitle">{{ $t("prestation.realtime.clientSupport") }}</div>
            <div class="alertBox prestationState operationInProgress">
              <span>{{ $t("prestation.realtime.actionInProgress") }}</span>
            </div>
          </div>

          <div v-if="prestation.garantee === $sncfRefs.getPrestaTypesByTechnicalName().garanteed" class="titleGarantee">
            {{ $t("prestation.mine.garanteed") }}
            <svgicon icon="star" class="prestaGarantedIcon" />
          </div>
          <div v-else class="titleGarantee">{{ $t("prestation.mine.notgaranteed") }}</div>
          <div class="infoPanel prestaRecap panelIsCompleted">
            <div class="infoPanelLine botBd infoPanelTitle grid-x">
              <div class="cell large-8 small-6 idTitle">{{ $t("parcours.id") }}</div>
              <div class="cell large-4 small-6 text-align-right">
                {{ parcours.name }}
                <UnlinkedIndicator :element="parcours" type="parcours" />
              </div>
            </div>
            <div class="infoPanelLine botBd infoPanelTitle grid-x">
              <div class="cell large-8 small-6 idTitle">{{ $t("prestation.id") }}</div>
              <div class="cell large-4 small-6 text-align-right">
                {{ prestation.number }}
                <UnlinkedIndicator :element="prestation" type="prestation" />
              </div>
            </div>
            <div class="infoPanelLine botBd infoPanelTitle grid-x">
              <div class="cell large-8 small-6 idTitle">{{ $t("prestation.status.status") }}</div>
              <div class="cell large-4 small-6 text-align-right">
                <div :class="`prestationStatus prestationStatus-${prestation.statusTN}`" v-if="prestation.statusTN !== null">
                  {{ $t("prestation.status." + prestation.statusTN) }}
                </div>
              </div>
            </div>
            <div class="infoPanelLine botBd infoPanelTitle grid-x" v-if="prestation.responsableAssistance">
              <div class="cell large-8 small-6 idTitle" >
                <div>
                  {{ $t("prestation.assistResp") }}
                </div>
                <div>
                  {{ $t("prestation.assistNature") }}
                </div>
              </div>
              <div class="cell large-4 small-6 text-align-right">
                <div>
                  {{ $t("prestation." + prestation.responsableAssistance) }}
                </div>
                <div>
                  {{ $t("prestation." + prestation.natureAssistance) }}
                </div>
              </div>
            </div>
            <div class="infoPanelLine botBd infoPanelTitle grid-x" v-else>
              <div class="cell text-align-left">
                {{ $t("prestation.noassistactors") }}
              </div>
            </div>
            <div class="infoPanelLine botBd infoPanelTitle grid-x" v-if="['notDone', 'reported'].includes(prestation.statusTN) && prestation.reason !== null">
              <div class="cell large-4 small-4 idTitle">{{ $t("prestation.status.reason") }}</div>
              <div class="cell large-8 small-8 text-align-right">{{ $t("prestation.reason." + prestation.reason) }}</div>
            </div>
            <div class="infoPanelLine botBd infoPanelTitle grid-x" v-if="displayInformedClientInfo">
              <div class="cell large-4 small-8 idTitle">{{ $t("prestation.userAwareInfo") }}</div>
              <div class="cell large-8 small-12 noInfoToClient" v-if="prestation.customerNotificationInformation === null">
                {{ $t("prestation.noInfoToClient") }}
              </div>
              <ul class="userWarnedInfoList" v-else>
                <li class="cell large-8 small-8 userWarnedInfo" v-for="userInfo in prestation.customerNotificationInformation.split(';')" :key="userInfo">
                  {{ $t("prestation."+userInfo) }}
                </li>
              </ul>
            </div>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="cell large-6 small-12">
              <JourneyCard :journey="prestation" @journeyData="saveJourney" :canEdit="canEditJourney" :stationDataFrom="stationData.mine" />
            </div>
            <div class="cell large-6 small-12">
              <Account :parcours="parcours" :account="account" :canEdit="false" :showParcoursNeeds="true" :showMail="true" />
            </div>
          </div>

          <StationPrestaCard :segmentPart="mine" :segmentsIndex="0" :stationData="stationData" :stationPresta="prestation" :journey="prestation" @stationPrestaData="savePrestation" :canEdit="false" />

          <div v-if="segments && segments.length > 0" class="otherPrestations">
            <div class="otherPrestationsTitle">{{ $t("prestation.allPrestas") }}</div>
            <div class="filters" v-if="hasCancelledPresta">
              <div class="titleStatus">{{ $t("prestation.filters.filterByStatus") }}</div>
              <div :class="showActive ? 'filterTablet filterChecked' : 'filterTablet'" @click="toggleValue('active')">{{ $t("prestation.filters.active") }}</div>
              <div :class="showCancelled ? 'filterTablet filterChecked' : 'filterTablet'" @click="toggleValue('cancelled')">{{ $t("prestation.filters.cancelled") }}</div>
            </div>
            <div v-if="showActive">
              <div v-for="(segment, index) in segmentsFilteredForActivePrestas" :key="index">
                <div class="grid-x grid-margin-x segment">
                  <span class="cell small-8 large-8 segmentTitle">{{ $t("prestation.segment.segment") }} {{ index + 1 }}</span>
                  <button class="cell small-4 large-4 reportChooseReasonButton" v-if="canReportSegment(segment[0]) && canReport" @click="openModalConfirm(segment[0])">
                    {{ $t("prestation.report.report") }}
                  </button>
                </div>
                <div class="segmentBorderLeftContainer">
                  <div class="segmentBorderLeft" />
                  <PrestationSegment :prestation="segment[0]" :showAsCurrentPresta="segment[0].herokuExternalId == prestation.herokuExternalId" :account="account" />
                  <PrestationSegment :prestation="segment[1]" :showAsCurrentPresta="segment[1].herokuExternalId == prestation.herokuExternalId" :account="account" v-if="segment.length > 1" />
                </div>
              </div>
            </div>
            <div v-if="showCancelled">
              <div class="otherPrestationsTitle">{{ $t("prestation.others") }}</div>
              <div v-for="(segment, index) in segmentsFilteredForCancelledPrestas" :key="index">
                <div class="grid-x grid-margin-x segment">
                  <span class="cell small-8 large-8 segmentTitle">{{ $t("prestation.segment.segment") }} {{ index + 1 }}</span>
                  <button class="cell small-4 large-4 reportChooseReasonButton" v-if="canReportSegment(segment[0]) && canReport" @click="openModalConfirm(segment[0])">
                    {{ $t("prestation.report.report") }}
                  </button>
                </div>
                <div class="segmentBorderLeftContainer">
                  <div class="segmentBorderLeft" />
                  <PrestationSegment :prestation="segment[0]" :showAsCurrentPresta="segment[0].herokuExternalId == prestation.herokuExternalId" :account="account" />
                  <PrestationSegment :prestation="segment[1]" :showAsCurrentPresta="segment[1].herokuExternalId == prestation.herokuExternalId" :account="account" v-if="segment.length > 1" />
                </div>
              </div>
            </div>
          </div>

          <div class="prestaHisto" v-if="canSeeHisto && histos && histos.length > 0">
            <div class="accordionContainer">
              <ul class="accordion" data-accordion data-allow-all-closed="true">
                <li class="accordion-item" data-accordion-item>
                  <a href="#" class="accordion-title">
                    {{ $t("prestation.histo") }}
                    <svgicon icon="arrow" class="arrow" />
                  </a>
                  <div class="accordion-content" data-tab-content>
                    <div v-for="histo in histos" :key="histo.id">
                      <HistoPrestationLine :histo="histo" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="actionButtons cell large-4 small-12 print-hide">
          <span v-if="isStopAndGoRunning || (prestation.stopAndGoState && isOperationStarted)" class="stopAndGoQualifyMessage">{{ $t('qualifyWithStopAndGo') }}</span>
          <button v-if="canQualify" @click="showQualify = true" class="qualify button-theme" :disabled="isStopAndGoRunning || (prestation.stopAndGoState && isOperationStarted)">{{ $t("qualify") }}</button>
          <span v-if="!hasService">
            <span
              v-tooltip="{
                content: $t('tooltip.service', { service: prestation.service }),
                trigger: 'hover click',
              }"
            >
              <button class="button-theme button-disabled cantQualify" disabled>{{ $t("qualify") }}</button>
            </span>
          </span>
          <!-- <button v-if="isOnBoard && canCreateArrival" class="button-light" @click="createArrival()">
            {{ $t("onBoardArrival") }}
          </button> -->
          <button v-if="isTodo && canSeeManager && !isOnBoardRestriction" @click="showAffect = true" class="button-light">{{ $t("agent.affect") }}</button>
          <button v-if="!prestation.noFollow && isDeparture && canSeeSolBord && $sncfRefs.isActiveFeatureByCode('REPLACE_PUPETEER') && !isOnBoardRestriction" class="solbordbutton button-light" @click="openSolBord">
            {{ $t("agent.solbord") }}
          </button>
          <button v-else-if="!prestation.noFollow && isDeparture && canSeeSolBord && !isOnBoardRestriction" class="solbordbutton button-light" @click="openSolBordPdf">
            {{ $t("agent.solbord") }}
          </button>
          <button v-if="$store.getters.userTransporter === 'Transilien' && prestation.garantee !== $sncfRefs.getPrestaTypesByTechnicalName().garanteed" class="button-light" @click="toGarantee">
            {{ $t("prestation.toGarantee") }}
          </button>
          <router-link :to="{ name: 'parcoursHisto', params: { herokuExternalId: parcours.herokuExternalId } }" v-if="$perms.isUserAllowed('presta.canSeeParcoursHisto')" class="button-light">
            {{ $t("prestation.goToParcoursHisto") }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- <div v-if="!isLoading" class="operationButtons fixedOverFooter"> -->
    <div v-if="displayStopAndGo" class="operationButtons fixedOverFooter">
      <div class="socaContent">
        <div class="grid-x socaContent-eatContentPadding">
          <div class="cell medium-12 small-12 grid-x grid-padding-x align-center align-middle" v-if="canStartOperation">
            <button @click="startOperationClicked" :disabled="isLoading" class="operation">
              {{ $t("prestation.realtime.startAction") }}
            </button>
          </div>
          <div class="cell medium-12 small-12 grid-x grid-padding-x align-center align-middle" v-else-if="isOperationStarted">
            <button @click="stopOperationClicked" :disabled="isLoading" class="operation">
              {{ $t("prestation.realtime.completeAction") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isLoading && showAffect" v-bind:class="{ hidden: !showAffect }">
      <AffectAgent @affectAgent="affectAgent" @closeAffect="closeAffect" :agentOld="prestation.manager" />
    </div>
    <div v-if="!isLoading && showQualify" v-bind:class="{ hidden: !showQualify }">
      <Qualify @qualify="qualify" @closeQualify="closeQualify" :prestationData="prestation" :parcoursData="parcours" @update="update"/>
    </div>
    <socalightmodal v-if="modalConfirm" class="reportModalConfirm" :title="$t('prestation.segment.modalTitle')" @close="modalConfirm = false" @submit="reportSegment">
      <div class="reportModalTxt">
        <svgicon class="alert" icon="alert" />
        {{ $t("report.modal.ok") }}
      </div>
      <span class="subtitle">{{ $t("report.modal.chooseReason") }}</span>
      <div class="formElement darkFormElement">
        <select v-model="reportReason" class="cell small-8 large-9 modalReportReasons">
          <option :value="null" selected>{{ $t("prestation.segment.reasonSelect") }}</option>
          <option v-for="motif in motifs" :value="motif" :key="motif">{{ $t("prestation.reason." + motif) }}</option>
        </select>
      </div>
    </socalightmodal>
    <socalightmodal v-if="modalOperation" class="operationModalConfirm" :title="canStartOperation ? $t('prestation.realtime.startOperation') : $t('prestation.realtime.stopOperation')" @close="modalOperation = false" @submit="canStartOperation ? startOperation() : stopOperation()">
      <div class="operationModalTxt">
        <svgicon class="alert" icon="alert" />
        {{ canStartOperation ?
          $t("prestation.realtime.startOperationQuestion", getStopAndGoModalQuestionParameters) :
          $t("prestation.realtime.stopOperationQuestion", getStopAndGoModalQuestionParameters) }}
      </div>
    </socalightmodal>
  </div>
</template>

<script>
import JourneyCard from "@/components/Prestations/Creation/Part/JourneyCard"
import StationPrestaCard from "@/components/Prestations/Creation/Part/StationPrestaCard"
import Account from "@/components/Prestations/Creation/Part/Account"
import PrestationSegment from "@/components/Prestations/PrestationSegment"
import AffectAgent from "@/components/Prestations/AffectAgent"
import Qualify from "@/components/Prestations/Qualify"
import UnlinkedIndicator from "@/components/Common/UnlinkedIndicator"
import HistoPrestationLine from "@/components/Prestations/Histo/HistoPrestationLine"
import commonService from "@/services/common"
import userService from '@/services/userService'

import notificationService from "@/services/notification"

import moment from "moment"
import { PRESTA_NATURE_ASSISTANCE_BORD } from "../../utils/constantsUtils"

const CANCELLED = 'Annulée'

export default {
  name: "Prestation",
  data() {
    return {
      herokuExternalId: null,
      prestation: null,
      account: null,
      parcours: null,
      mine: "mine",
      stationData: { mine: null },
      segments: null,
      segmentsFilteredForActivePrestas: null,
      segmentsFilteredForCancelledPrestas: null,
      allPrestas: null,
      hasCancelledPresta: false,
      isLoading: true,
      loadError: null,
      principalState: null,
      isTodo: null,
      showAffect: false,
      showQualify: false,
      canQualify: false,
      isStopAndGoRunning: false,
      isDeparture: false,
      canCancel: false,
      canEditJourney: false,
      isWarned: false,
      orderedAlerts: {},
      nbOrderedAlerts: 0,
      histos: null,
      hasService: true,
      modalConfirm: false,
      reportReason: null,
      prestaToReport: null,
      prestationToExport: {},
      prestationOtherSide: {},
      prestationCorrespondances: [],
      modalOperation: false,
      isOnBoardArrival: false,
      showCreateArrivalButton: false,
      PRESTA_NATURE_ASSISTANCE_BORD,
      showActive: true,
      showCancelled: false
    }
  },
  created() {
    this.fetchDatas()
  },
  mounted() {
    this.motifs = this.$sncfRefs.getPrestaReasonsTechnicalNames()
    const toDel = [
      "trainRemovedOtherStation",
      "substitutionOtherStation",
      "customerCancellation",
      "wheelchair",
      "wheelchairOtherStation",
      "noShowClient",
      "excessBaggage",
      "postponementArrival",
      "entryErrorOtherStation",
    ]
    toDel.map((m) => this.motifs.splice(this.motifs.indexOf(m), 1))

    this.herokuExternalId = this.$route.params.herokuExternalId

    let self = this
    notificationService.addNotificationCallback("prestationfile" + self.herokuExternalId, function(notification) {
      if (notification.prestationHcId === self.herokuExternalId) {
        self.prestation = notification.Prestation
      }
    })
    window.addEventListener("beforeunload", (event) => {
      this.$root.$emit('filterPrestationReset')
    })
  },
  beforeRouteLeave (to, from, next) {
    if (!to.path.includes('/prestations')) {
      this.$root.$emit('filterPrestationReset')
    }
    next()
  },
  methods: {
    print() {
      // window.print()
      window.open(process.env.VUE_APP_OPENID_REDIRECT_BASE_URL + "/prestations/" + this.prestation.herokuExternalId + "/print", "_blank")
    },
    toggleValue(value) {
      switch (value) {
        case 'active':
          this.showActive = !this.showActive
          break;
        case 'cancelled':
          this.showCancelled = !this.showCancelled
          break;
        default:
          break;
      }
    },
    openSolBordPdf: function() {
      return this.$backendConnector
        .solBordPdf(this.prestation.herokuExternalId)
        .then((result) => {
          commonService.downloadBlob(result.data, "solbord" + this.prestation.herokuExternalId + ".pdf")
        })
        .catch((err) => console.error(err))
    },
    openSolBord: function() {
      window.open(process.env.VUE_APP_OPENID_REDIRECT_BASE_URL + "/prestations/" + this.prestation.herokuExternalId + "/contactSolBord", "_blank")
    },
    fetchDatas: function() {
      return this.getPrestation().catch((error) => {
        console.log(error, "Something went wrong")
        this.loadError = this.$t("prestation.noaccess")
      })
    },
    getPrestation: function() {
      const prestationHcId = this.$route.params.herokuExternalId
      return this.$backendConnector
        .getPrestation(prestationHcId)
        .then((prestation) => (this.prestation = prestation))
        .then(() => this.$backendConnector.getGareByUic(this.prestation.garePrestaUic))
        .then((gare) => (this.stationData.mine = gare))
        .then(() => this.$backendConnector.getParcours(this.prestation.parcoursHerokuExternalId))
        .then((parcours) => {
          this.parcours = parcours
          this.account = parcours.Account
        })
        .then(() => this.$backendConnector.getPrestationHisto(prestationHcId))
        .then((histos) => (this.histos = histos))
        .then(() => this.$backendConnector.getPrestations({ searchParams: { parcoursHerokuExternalId: this.parcours.herokuExternalId } }))
        .then((results) => {
          this.allPrestas = results.docs.sort((a, b) => (moment(a.datePrestation).isAfter(moment(b.datePrestation)) ? 1 : -1))
          // this.principalState = commonService.getPrestationPrincipalState(this.prestation.herokuExternalId, this.allPrestas)
          this.segments = commonService.getSegmentsFromPrestations(this.allPrestas)
          this.formatSegmentForDisplay()
          // Find segment with mention 'A'
          const arrivalSegment = this.segments[0].find(segment => segment.mention === 'A')
          this.showCreateArrivalButton = !arrivalSegment

          this.checkAlerts()
          this.isLoading = false
        })
        .then(() => {
          this.isTodo = this.prestation.statusTN === "todo"
          if (this.$store.getters.userRole === "TRANSPORTER") {
            this.hasService = this.$store.getters.userServices.includes(this.prestation.service)
          }
          this.canCancel = this.$perms.isUserAllowed("presta.cancel") && this.hasService
          const isQualificationPossible = (this.$perms.isUserAllowed("presta.management") && !this.isOnBoardRestriction &&
              (this.$perms.isUserAllowed("presta.stations.all") ||
                ((this.$perms.isUserAllowed("presta.stations.self") || this.isOnBoard) && this.$store.getters.possibleStations.map((s) => s.codeUic).includes(this.prestation.garePrestaUic)))) ||
            this.canCancel
          const canTransporterQualify = moment().isBefore(moment(this.prestation.datePrestation)) && isQualificationPossible // un transporteur ne peut pas qualifier une prestation dans le passé
          this.canQualify = this.$store.getters.userRole === "TRANSPORTER" ? canTransporterQualify : isQualificationPossible
          if ([this.$sncfRefs.getMentionsByTechnicalName().departure.sigle, this.$sncfRefs.getMentionsByTechnicalName().transitDeparture.sigle].includes(this.prestation.mention)) {
            this.isDeparture = true
          }
          this.canSeeSolBord = this.$perms.isUserAllowed("presta.solbord")
          this.canEditJourney = this.$perms.isUserAllowed("presta.edit") && this.prestation.statusTN === "todo"
          this.isWarned = !!this.prestation.warningSource && !!this.prestation.warningDatetime
        })
        .then(() =>
          this.$nextTick().then(() => {
            $(this.$el).foundation()
          })
        )
        .then(() => this.setHours())
    },
    setHours: function() {
      this.prestation.hourAsked = moment(this.prestation.dateAsked).format("HH:mm")
      this.prestation.datePrestationHour = moment(this.prestation.datePrestation).format("HH:mm")
    },
    checkAlerts() {
      if (this.prestation.garantee !== this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed) {
        this.orderedAlerts = {}
        this.nbOrderedAlerts = 0
        let prestasWithAlertsToCheck = this._.cloneDeep(this.allPrestas).filter((presta) => {
          return moment(presta.datePrestation).isSameOrAfter(moment(this.prestation.datePrestation)) && this.prestation.herokuExternalId !== presta.herokuExternalId
        })
        prestasWithAlertsToCheck.map((p) => {
          if (
            !(p.state === this.$sncfRefs.getPrestaStatesByTechnicalName().accepted && p.mention === this.$sncfRefs.getMentionsByTechnicalName().departure.sigle) && // on n'affiche pas l'acceptation par la gare de départ
            !(p.state === this.$sncfRefs.getPrestaStatesByTechnicalName().accepted && p.garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed) // ni sur une presta garantie
          ) {
            if (p.state !== this.$sncfRefs.getPrestaStatesByTechnicalName().reported && p.mention !== this.$sncfRefs.getMentionsByTechnicalName().none.sigle && !p.autoAccepted) {
              let alertType =
                p.state === this.$sncfRefs.getPrestaStatesByTechnicalName().accepted ? "accepted" : p.state === this.$sncfRefs.getPrestaStatesByTechnicalName().refused ? "refused" : "waiting"
              if (this.orderedAlerts[alertType]) {
                this.orderedAlerts[alertType].push(p)
              } else {
                this.orderedAlerts[alertType] = [p]
              }
              this.nbOrderedAlerts++
            }
          }
        })
      }
    },
    affectAgent(val) {
      this.closeAffect()
      return this.savePrestation({ manager: val }, "prestation.affect.done").catch((error) => console.log(error))
    },
    // Update if status not changed, otherwise qualify
    update (data) {
      return this.editParcours({ parcours: { bagage: data.bagage } })
        .then(() => this.savePrestation(data.prestation))
        .then(() => this.syncSegment({ prestation: { commentaryQualifOther: this.prestation.commentaryQualif, trainCar: this.prestation.trainCar, trainPlace: this.prestation.trainPlace } }))
        .catch(error => {
          console.log(error)
          this.$toastService.showToast(this.$t('prestation.qualify.already'), null, 'error')
        })
    },
    qualify(data) {
      if (data.prestation.statusTN === "reported") {
        data.prestation.presentationTime = data.prestation.presentationTime ? data.prestation.presentationTime.toString() : null
        data.originalDeparture = { og: this.prestation, params: { prestation: data.prestation } }
      }
      return this.editParcours({ parcours: { bagage: data.bagage } })
        .then(() => this.savePrestation(data.prestation))
        .then(() => this.syncSegment({ prestation: { commentaryQualifOther: this.prestation.commentaryQualif, trainCar: this.prestation.trainCar, trainPlace: this.prestation.trainPlace } }))
        .then(() => this.isReported(data, false))
        .catch((error) => {
          console.error(error)
          this.$toastService.showToast(this.$t("prestation.qualify.already"), null, "error")
        })
    },
    startOperationClicked: function() {
      if (this.canStartOperation) {
        this.modalOperation = true
      } else {
        this.$toastService.showToast(this.$t("prestation.realtime.actionAlreadyStarted"))
      }
    },
    startOperation: function() {
      if (this.canStartOperation) {
        this.modalOperation = false
        this.isLoading = true
        this.savePrestation({ stopAndGoState: "STARTED" })
          .then(() => {
            this.isLoading = false
            this.isStopAndGoRunning = true
          })
          .then(() => notificationService.sendStopAndGoEvent(this.prestation))
          .catch((error) => console.log(error))
      } else {
        this.modalOperation = false
        this.$toastService.showToast(this.$t("prestation.realtime.actionAlreadyStarted"))
      }
    },
    stopOperationClicked: function() {
      if (this.isOperationStarted) {
        this.modalOperation = true
      } else if (this.operationCompleted) {
        this.$toastService.showToast(this.$t("prestation.realtime.actionAlreadyCompleted"))
      } else {
        this.$toastService.showToast(this.$t("prestation.realtime.noActionStarted"))
      }
    },
    stopOperation: function() {
      if (this.isOperationStarted) {
        this.modalOperation = false
        this.isLoading = true
        this.savePrestation({ stopAndGoState: "COMPLETED" })
          .then(() => {
            this.isLoading = false
            this.isStopAndGoRunning = false
          })
          .then(() => notificationService.sendStopAndGoEvent(this.prestation))
          .catch((error) => console.log(error))
      } else if (this.operationCompleted) {
        this.modalOperation = false
        this.$toastService.showToast(this.$t("prestation.realtime.actionAlreadyCompleted"))
      } else {
        this.modalOperation = false
        this.$toastService.showToast(this.$t("prestation.realtime.noActionStarted"))
      }
    },
    toGarantee: function() {
      return this.savePrestation({ garantee: this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed }).catch((error) => console.log(error))
    },
    saveJourney(data) {
      const presta = { trainCar: data.trainCar, trainPlace: data.trainPlace, trainNumber: data.trainNumber, missionCode: data.missionCode }
      return this.savePrestation(presta)
        .then(() => this.syncSegment({ prestation: presta }))
        .catch((error) => console.log(error))
    },
    openModalConfirm(prestation) {
      this.modalConfirm = true
      this.prestaToReport = prestation
    },
    canReportSegment(presta) {
      return presta.segmentPart === "from" && moment(presta.datePrestation).isAfter(moment(this.prestation.datePrestation))
    },
    reportSegment() {
      if (!this.reportReason) {
        return null
      }
      const self = this
      return new Promise(function(resolve, reject) {
        self.modalConfirm = false
        let prestation = self.prestaToReport
        prestation.statusTN = "reported"
        prestation.state = "reportée"
        prestation.reason = self.reportReason
        let data = { bagage: self.parcours.bagage, prestation: prestation }
        data.originalDeparture = { og: prestation, params: { prestation: prestation } }
        resolve(data)
      })
        .then((data) => this.isReported(data, false))
        .catch((err) => console.log(err))
    },
    isReported(data, alreadyQualified) {
      if (data.prestation.statusTN === "reported" && !alreadyQualified) {
        // const availableDates = {
        //   start: data.prestation.datePrestation,
        //   end: moment(data.prestation.datePrestation).add(1, 'days').format()
        // }
        // // save next step with new status and motifs
        // const params = { prestation: { statusTN: 'notDone',
        //   reason: 'postponementDeparture',
        //   state: 'reportée' } }
        // // remove prestation already passed
        // const nextPrestas = []
        // this.allPrestas.map(p => {
        //   if (moment(p.datePrestation).isSameOrAfter(moment(data.prestation.datePrestation)) &&
        //       p.herokuExternalId !== data.prestation.herokuExternalId &&
        //       p.statusTN !== 'reported' &&
        //       p.state !== this.$sncfRefs.getPrestaStatesByTechnicalName().reported
        //   ) {
        //     nextPrestas.push(p)
        //   }
        // })
        // const originalArrival = { og: nextPrestas[0], params: params }
        // let canChangeGarantee = false
        // if (data.prestation.transporter === 'TRANSILIEN') {
        //   canChangeGarantee = (data.prestation.garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed && ['customerDelay', 'trainRemovedOtherStation'].includes(data.prestation.reason))
        // }
        // let prestations = { current: data, others: nextPrestas, account: this.account, parcours: this.parcours, availableDates: availableDates, originalDeparture: data.originalDeparture, originalArrival: originalArrival }
        // this.$router.push({ name: 'report2', query: { reported: true, canChangeGarantee: canChangeGarantee, prestations: prestations } })
        this.$router.push({ name: "report", params: { herokuExternalId: data.prestation.herokuExternalId, reason: data.prestation.reason } })
      }
    },
    syncSegment(data) {
      if (this.prestation.commentaryQualif || this.prestation.trainNumber || this.prestation.missionCode || this.prestation.trainCar || this.prestation.trainPlace) {
        // get other side of segment
        let otherSidePresta = null
        this.allPrestas.forEach((prestaToCheck, idx) => {
          // segment part must be different and other station must be equal to presta station (and not reported)
          if (
            prestaToCheck.state !== this.$sncfRefs.getPrestaStatesByTechnicalName().reported &&
            prestaToCheck.segmentPart !== this.prestation.segmentPart &&
            prestaToCheck.gareAutreUic === this.prestation.garePrestaUic
          ) {
            otherSidePresta = prestaToCheck
          }
        })
        data = this._.cloneDeep(data)
        if (otherSidePresta) {
          data.prestation.herokuExternalId = otherSidePresta.herokuExternalId
          return this.$backendConnector.updatePrestation(data.prestation).catch((err) => console.log(err))
        }
      }
    },
    // close modales
    closeAffect() {
      this.showAffect = false
    },
    closeQualify() {
      this.showQualify = false
      return this.fetchDatas()
    },
    // backend connector
    savePrestation(prestaToSave, message = "prestation.updated") {
      prestaToSave = this.cleanPrestaForQualif(prestaToSave)
      if (prestaToSave.statusTN === "reported") return
      return this.$backendConnector
        .updatePrestation(prestaToSave)
        .then(() => this.fetchDatas())
        .then(() => this.setHours())
        .then(() => this.$toastService.showToast(this.$t(message), null, "success"))
        .catch(console.error)
    },
    editParcours: function(params) {
      return this.$backendConnector
        .editParcours(this.parcours.herokuExternalId, params)
        .then((parcours) => {
          this.parcours = parcours
        })
        .catch((error) => console.log(error))
    },
    cleanPrestaForQualif: function(prestaIn) {
      // allow only certains fiels to be sent in a qualif, to avoid some sync issues when rewriting fields
      let prestaToSendForQualif = {}
      let fieldsForQualif = [
        "herokuExternalId",
        "statusTN",
        "status",
        "reason",
        "state",
        "presentationTime",
        "qualified",
        "manager",
        "trainCar",
        "trainPlace",
        "warningSource",
        "warningDatetime",
        "commentary",
        "commentaryTransporter",
        "commentarySecondaryTransporter",
        "commentaryQualif",
        "commentaryQualifOther",
        "onboardingDocStatus",
        "onboardingDocComment",
        "stopAndGoState",
        "customerNotificationInformation"
      ]
      if (!prestaIn.herokuExternalId) prestaIn.herokuExternalId = this.prestation.herokuExternalId // Pour assurer l'envoi de l'ID
      for (let i in fieldsForQualif) {
        let field = fieldsForQualif[i]
        if (prestaIn[field] !== undefined) {
          prestaToSendForQualif[field] = prestaIn[field]
        }
      }
      return prestaToSendForQualif
    },
    createArrival() {
      this.isOnBoardArrival = true
      this.account.sfid = this.stationData.mine.sfid
      this.$store.commit('onBoardPrestation', this.prestation)
      this.$store.commit('onBoardAccount', this.account)
      this.$store.commit('onBoardParcours', this.parcours)
      this.$store.commit('onBoardStationData', this.stationData.mine)
      this.$store.commit('isOnBoardArrival', this.isOnBoardArrival)
      this.$router.push({ name: 'PrestationCreation', params: { step: 2 } })
    },
    formatSegmentForDisplay() {
      this.segmentsFilteredForActivePrestas = this.organizeSegment(false)
      this.segmentsFilteredForCancelledPrestas = this.organizeSegment(true)
      this.hasCancelledPresta = this.segmentsFilteredForCancelledPrestas.length > 0 && this.allPrestas.some(presta => presta.status === CANCELLED)
    },
    organizeSegment(forCancelledPresta) {
      return this.segments.reduce((result, segment) => {
        const validSegment = segment.filter(presta => {
          return forCancelledPresta ? presta.status === CANCELLED : presta.status !== CANCELLED
        })
        validSegment.length > 0 && result.push(validSegment)
        return result
      }, [])
    }
  },
  computed: {
    canSeeManager: function() {
      return this.$perms.isUserAllowed("presta.canSeeManager")
    },
    canSeeHisto: function() {
      return this.$perms.isUserAllowed("presta.canSeeHisto")
    },
    canReport: function() {
      return this.$perms.isUserAllowed("presta.report")
    },
    displayStopAndGo: function() {
      if (this.prestation && this.stationData.mine) {
        return (
          this.canQualify &&
          this.prestation.statusTN === "todo" &&
          this.$sncfRefs.isActiveFeatureByCode("STOP_AND_GO") &&
          this.prestation.transportMode === "Train" &&
          this.$sncfRefs.getTransporterModelByCode(this.prestation.transporter).realtimeExchange &&
          // Enable Stop & Go for ONBOARD crew even if the station do not use Stop & Go
          (this.stationData.mine.stopAndGo || (userService.isOnBoardCrew() && !this.stationData.mine.stopAndGo)) &&
          (this.prestation.stopAndGoState ? !this.prestation.stopAndGoState.startsWith("COMPLETED") : true)
        )
      } else {
        return false
      }
    },
    canStartOperation: function() {
      return this.prestation && (!this.prestation.stopAndGoState)
    },
    isOperationStarted: function() {
      return this.prestation && this.prestation.stopAndGoState.startsWith("STARTED")
    },
    operationInProgress: function() {
      return this.prestation.stopAndGoState && this.prestation.stopAndGoState.startsWith("STARTED")
    },
    operationCompleted: function() {
      return this.prestation.stopAndGoState && this.prestation.stopAndGoState.startsWith("COMPLETED")
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    },
    isOnBoardPresta() {
      return this.prestation?.responsableAssistance === 'Transporteur' && this.prestation?.natureAssistance === PRESTA_NATURE_ASSISTANCE_BORD
    },
    isOnBoardRestriction() {
      return !this.isOnBoardPresta && this.isOnBoard
    },
    canCreateArrival() {
      return this.prestation.mention.includes('D', 'DC') && this.showCreateArrivalButton
    },
    displayInformedClientInfo() {
      return this.prestation.statusTN !== 'todo' || this.prestation.customerNotificationInformation !== null
    },
    getPrestationHour() {
      return this.prestation.datePrestation ? moment(this.prestation.datePrestation).format('HH:mm') : ''
    },
    getFormatedPrestationMention() {
      return this.prestation.mention ? this.$t(`prestation.mentionForStopAndGoModal.${this.prestation.mention}`) : ''
    },
    getStopAndGoModalQuestionParameters() {
      return {
        title: this.parcours.Account.civility,
        lastname: this.parcours.Account.lastname,
        firstname: this.parcours.Account.firstname || '',
        trainNumber: this.prestation.trainNumber,
        mention: this.getFormatedPrestationMention,
        dateTime: this.getPrestationHour
      }
    }
  },
  components: {
    JourneyCard,
    StationPrestaCard,
    Account,
    PrestationSegment,
    AffectAgent,
    Qualify,
    UnlinkedIndicator,
    HistoPrestationLine,
  },
}
</script>
