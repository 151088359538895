<template>
  <div id="prestationNewStep1">
    <form>
      <div class="socaContent socaContentNarrow prestationAccountForm">
        <div class="prestationCreationTitle">{{$t('prestation.new') }}</div>
        <div class="titleStep">{{$t('prestation.create.base') }}</div>
        <div>
          <div class="prestationTypeChoices">
            <div class="grid-x grid-margin-x">              <div
                @click="$emit('toStandardPresta')"
                class="button-round cell small-6"
              >{{$t('prestation.create.standard') }}</div>
              <div
                class="button-round button-round-selected cell small-6"
              >{{$t('prestation.create.sanssuite') }}</div>
            </div>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="medium-6 small-12 cell">
              <div class="formElement">
                <label>
                  {{ $t('account.title') }}* <small
                    v-if="validation.hasError('account.civility')"
                    class="error"
                  >{{ $t('error.field.mandatory') }}</small>
                  <selectpl v-model="account.civility">
                    <option v-for="civility in civilities" :value="civility" :key="civility">{{ $t('common.civility.'+ $sncfRefs.getCivilityTechnicalNameByLabel(civility)) }}</option>
                  </selectpl>
                </label>
              </div>
              <div class="formElement">
                <label>
                  {{ $t('account.firstname') }} <small
                    v-if="validation.hasError('account.firstname')"
                    class="error"
                  >{{ $t('error.field.mandatory') }}</small>
                  <input
                    type="text"
                    :placeholder="$t('account.firstname')"
                    v-model="account.firstname"
                    @input="searchAccount"
                  />
                </label>
              </div>
              <div class="formElement">
                <label>
                  <span
                    v-tooltip="{
                                  content: 'Si le client refuse de communiquer son nom, indiquez Anonyme',
                                  trigger: 'hover click',
                                }"
                  >{{ $t('account.lastname') }}*</span> <small
                    v-if="validation.hasError('account.lastname')"
                    class="error"
                  >{{ $t('error.field.mandatory') }}</small>
                  <input
                    type="text"
                    :placeholder="$t('account.lastname')"
                    v-model="account.lastname"
                    @input="searchAccount"
                  />
                </label>
              </div>

              <div>
                <label>
                  <span v-tooltip="{
                          content: 'Si le client refuse de communiquer son n° de téléphone, laissez le champ vide.',
                          trigger: 'hover click',
                        }">
                    {{ $t('account.phone') }}
                  </span>
                  <small v-if="validation.hasError('account.phone')" class="error">{{ $t('error.field.incorrectFormat') }}</small>
                  <input type="tel" :placeholder="$t('account.phone')" v-model="account.phone" @input="searchAccount">
                </label>
              </div>

              <div class="formElement">
                <label>{{$t('prestation.station.stationOfX', {station: ""}) }}</label>
                <input type="text" disabled :value="contextStationPresta.name" />
              </div>
              <div class="formElement position-relative">
                <label>
                  <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv"/>
                  <span>{{$t('prestation.trajet.trainnumber') }}*</span> <small
                    v-if="validation.hasError('trajet.trainNumber')"
                    class="error"
                  >{{ $t('error.field.mandatory') }}</small>
                  <input
                    type="text"
                    :placeholder="`${$t('prestation.trajet.trainnumberplaceholder') }`"
                    v-model="trajet.trainNumber"
                    v-debounce="fetchPivDataTrainNumber"
                    :disabled="isOnBoard"
                  />
                </label>
              </div>
              <div class="formElement position-relative" v-if="!isOnBoard">
                <label>
                  <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv"/>
                  <span>{{$t('prestation.trajet.missioncode') }}*</span> <small
                    v-if="validation.hasError('trajet.missionCode')"
                    class="error"
                  >{{ $t('error.validator.lengthBetween', {0: 3, 1: 15}) }}</small>
                  <input
                    type="text"
                    :placeholder="`${$t('prestation.trajet.missioncodeplaceholder') }`"
                    v-model="trajet.missionCode"
                    v-debounce="fetchPivDataMissionCode"
                  />
                </label>
              </div>
              <div class="formElement">
                <label>
                  {{ $t("prestation.trajet.transporter") }}*
                  <small v-if="validation.hasError('trajet.transporter')" class="error">{{ $t("error.field.mandatory") }}</small>
                </label>
                <select v-model="trajet.transporter" v-if="!isOnBoard">
                  <option v-for="transporter in transporters" :value="transporter.code" :key="transporter.code">{{ transporter.name }}</option>
                </select>
                <input v-if="isOnBoard" type="text" v-model="trajet.transporter" disabled />
              </div>
            </div>

            <div class="medium-6 small-12 cell">
              <div class="formElement">
                <label>
                  {{ $t('account.typeAssist') }} <small v-if="validation.hasError('account.typeAssist')" class="error">{{ $t('error.field.mandatory') }}</small>
                  <select
                    v-model="account.typeAssist"
                    @change="onChangeType($event.target.value)"
                    :class="{ 'valueIsNull': account.typeAssist === null }"
                  >
                    <option :value="null" hidden>{{ $t('common.select') }}</option>
                    <option :value="null">-</option>
                    <option :key="option.acronym" v-for="option in typeAssist" :value="option.acronym">{{option.allLetters}}</option>
                  </select>
                </label>
              </div>
              <div class="formElement">
                <label>
                  {{ $t('account.specAssist') }} <small v-if="validation.hasError('account.specAssist')" class="error">{{ $t('error.field.mandatory') }}</small>
                  <select
                    v-model="account.specAssist"
                    :class="{ 'valueIsNull': account.specAssist === null }"
                  >
                    <option :value="null" hidden>{{ $t('common.select') }}</option>
                    <option :value="null">-</option>
                    <option :key="spec.acronym" v-for="spec in specAssist" :value="spec.acronym">{{spec.allLetters}}</option>
                  </select>
                </label>
              </div>
              <div class="formElement">
                <label>
                  {{ $t('account.detailAssist') }}
                  <select v-model="account.detailAssist" :class="{ 'valueIsNull': account.detailAssist === null }">
                    <option :value="null" hidden>{{ $t('common.select') }}</option>
                    <option :value="null">-</option>
                    <option :key="detail.acronym" v-for="detail in detailAssist" :value="detail.acronym">{{detail.allLetters}}</option>
                  </select>
                </label>
              </div>

              <div class="formElement">
                <label>
                  {{ $t('account.specAssistSecondary') }}
                  <select
                    v-model="account.specAssistSecondary"
                    @change="onChangeSpecSecondary($event.target.value)"
                    :class="{ 'valueIsNull': account.specAssistSecondary === null }"
                  >
                    <option :value="null" hidden>{{ $t('common.select') }}</option>
                    <option :value="null">-</option>
                    <option :key="spec.acronym" v-for="spec in specAssist" :value="spec.acronym">{{spec.allLetters}}</option>
                  </select>
                </label>
              </div>
              <div class="formElement">
                <label>
                  {{ $t('account.detailAssistSecondary') }}
                  <select v-model="account.detailAssistSecondary" :class="{ 'valueIsNull': account.detailAssistSecondary === null }">
                    <option :value="null" hidden>{{ $t('common.select') }}</option>
                    <option :value="null">-</option>
                    <option :key="detail.acronym" v-for="detail in detailAssistSecondary" :value="detail.acronym">{{detail.allLetters}}</option>
                  </select>
                </label>
              </div>

              <div class="formElement" v-if="!isOnBoard">
                <label>
                  <span v-tooltip="{
                      content: $t('tooltip.bagage'),
                      trigger: 'hover click',
                    }">{{ $t('parcours.bagageNumber') }}</span>
                  <input
                    type="number"
                    min="0"
                    :placeholder="$t('parcours.bagageNumber')"
                    v-model="parcours.bagage"
                  />
                </label>
              </div>

              <div class="formElement">
                <label>{{$t('prestation.station.mention') }}* </label> <small v-if="validation.hasError('station.mention')" class="error">{{ $t('error.field.mandatory') }}</small>
                <select v-model="station.mention" >
                  <option v-for="mention in mentions" :value="mention.sigle" :key="mention.sigle">{{mention.label}}</option>
                </select>
              </div>
              <div class="formElement formElementMultiselect" v-if="isOnlyDeparturePrestation()">
                <label>{{$t('prestation.station.selectArrivalStation') }}</label>
                <Multiselect v-model="selectedStation" :options="stationsList"
                    :searchable="true" :internal-search="false" :options-limit="100"
                    @search-change="asyncStationSearch" @input="selectStation" @select="selectStation"
                    label="name" track-by="codeUic" :placeholder="`${$t('prestation.station.typeToSearch') }`"
                    deselectLabel="" selectLabel="" selectedLabel="">
                  <template slot="placeholder">{{$t('prestation.station.chooseStation') }}</template>
                  <template slot="noOptions">{{$t('prestation.station.searchNoResults') }}</template>
                  <template slot="noResult">{{$t('prestation.station.searchNoResults') }}</template>
                  <template slot="singleLabel" slot-scope="value">
                    <span>{{$t('prestation.station.stationOfX', {station: value.option.name}) }}</span>
                  </template>
                </Multiselect>
              </div>
              <div class="formElement">
                <label>{{$t('prestation.statusLbl') }}</label>
                <input type="text" disabled :value="trajet.status" />
              </div>
              <div class="formElement">
                <label>
                  {{ $t('station.motif') }}* <small
                  v-if="validation.hasError('station.reason')"
                  class="error"
                >{{ $t('error.field.mandatory') }}</small>
                  <select v-model="station.reason">
                    <option v-for="motif in motifs" :value="motif" :key="motif">{{ $t('prestation.reason.'+motif) }}</option>
                  </select>
                </label>
              </div>
              <div class="formElement">
                  <label>{{$t('prestation.userAwareInfo') }} <small v-if="validation.hasError('prestation.userAwareInfo')" class="error">{{ $t('error.field.mandatory') }}</small></label>
                  <Multiselect
                    class="large"
                    v-model="customerInformationType"
                    :options="customerInformationList.map(option => $t('prestation.'+option))"
                    :multiple="true"
                    :searchable="false"
                    :close-on-select="false"
                    :placeholder="`${$t('common.select')}`"
                    :show-labels="false">
                  </Multiselect>
                  <template slot="selection" slot-scope="{ values, search, isOpen }" v-if="values.length">
                    <span v-show="!isOpen" v-for="value in values" :key="value">
                      {{ value }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ $t('prestation.'+props.option) }}
                  </template>
              </div>
              <div class="formElement">
                <label>{{$t('prestation.station.commentary') }}<span v-if="mandatoryCommentary">*</span> <small
                  v-if="validation.hasError('station.commentary')"
                  class="error"
                >{{ $t('error.field.mandatory') }}</small>
                </label>
                <textarea
                  rows="5"
                  cols="33"
                  :placeholder="`${$t('prestation.station.commentaryplaceholder') }`"
                  v-model="station.commentary"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="prestationAccountFormFooter">
          <div class="mandatoryElementInfo">{{$t('common.field.mandatoryFields')}}</div>
          <button @click.prevent="validate" class="button-theme">{{$t('prestation.create.create')}}</button>
        </div>
      </div>
    </form>
    <AccountSearchCards
      @chooseAccount="chooseAccount"
      :accountSearch="accountSearch"
      :accounts="accounts"
       v-if="$perms.isUserAllowed('account.search')"
    />
  </div>
</template>

<script>
import assist from '@/services/assist'
import pivService from '@/services/piv'
import userService from '@/services/userService'
import { filterStations } from '@/services/stationService'

import AccountSearchCards from '@/components/Accounts/SearchCards'

import Multiselect from 'vue-multiselect/src/Multiselect'
import SimpleVueValidation from 'simple-vue-validator'
const Validator = SimpleVueValidation.Validator

export default {
  name: 'SansSuite',
  props: ['contextStationPresta'],
  data () {
    return {
      account: {
        civility: null,
        firstname: null,
        lastname: null,
        phone: null,
        typeAssist: null,
        specAssist: null,
        detailAssist: null,
        specAssistSecondary: null,
        detailAssistSecondary: null
      },
      parcours: {
        bagage: null,
        status: 'Non réalisé'
      },
      trajet: {
        trainNumber: null,
        transporter: null,
        missionCode: null,
        garantee: this.$sncfRefs.getPrestaTypesByTechnicalName().notGaranteed,
        origin: 'Front',
        status: 'Non réalisée',
        statusTN: 'notDone',
        state: this.$sncfRefs.getPrestaStatesByTechnicalName().refused,
        service: 'Spontané',
        noFollow: true,
        transportMode: this.$sncfRefs.getTransportModesByTechnicalName().train,
        trainDeparture: (new Date()),
        datePrestation: (new Date())
      },
      station: {
        mention: null,
        reason: null,
        commentary: null,
        name: null,
        codeUic: null,
        sfidStation: null
      },
      typeAssist: [],
      specAssist: [],
      detailAssist: [],
      detailAssistSecondary: [],
      civilities: [],
      motifs: [],
      mentions: [],
      transporters: [],
      accountSearch: false,
      accounts: null,
      isLoadingPiv: false,
      pivSearchTrainNumber: null,
      pivSearchMissionCode: null,
      customerInformationList: [],
      customerInformationType: [],
      selectedStation: null,
      stationsSearchResult: []
    }
  },
  computed: {
    isOnBoard() {
      return userService.isOnBoardCrew()
    },
    stationsList: function () {
      if (this.stationsSearchResult.length > 0) {
        return this.stationsSearchResult
      }
      return []
    },
    mandatoryCommentary() {
      return this.station.reason === 'other'
    }
  },
  created () {
    this.mentions = this.isOnBoard ? this.$sncfRefs.getOnBoardMentions() : this.$sncfRefs.getMentions()
    this.civilities = this.$sncfRefs.getCivilities()

    this.customerInformationList = this.$sncfRefs.getCustomerInformationList()
  },
  mounted () {
    this.account.civility = this.civilities[1]
    this.account.firstname = 'Anonyme'
    this.account.lastname = 'Anonyme'
    this.typeAssist = this.$sncfRefs.getTypeAssist()
    this.station.mention = this.$sncfRefs.getMentionsByTechnicalName().departure.sigle
    this.station.codeUic = this.contextStationPresta.codeUic
    this.motifs = ['unavailableEquipment', 'trainRemoved', 'excessBaggage', 'wheelchair', 'customerDelay', 'noPersonnelAvailable', 'trainLate', 'trainStationIncompatibility', 'arrivalStationInaccessibility', 'invalidTicket', 'nonEligibleAssistant', 'other']
    this.transporters = this.$sncfRefs.getTransportersModelsForUpsert()
    this.parcours.bagage = 0
    if (this.isOnBoard) {
      this.setOnBoardData()
    }
    this.isOnlyDeparturePrestation()
  },
  methods: {
    validate () {
      const newPresta = this.formatSegments(this._.clone(this.trajet), this.station)

      if (this.customerInformationType.length > 0) {
        newPresta.customerNotificationInformation = this.customerInformationList
          .filter(item =>
            this.customerInformationType.includes(this.$t('prestation.' + item)
            )
          ).join(';')
      }

      const self = this
      this.$validate().then(function (success) {
        if (success) {
          self.$emit('sansSuiteStepOk', self.account, self.parcours, newPresta)
        }
      })
    },

    formatSegments (journey, seg, otherSeg) {
      let newPresta = this._.assign(journey, seg)
      newPresta.garePrestaUic = journey.codeUic
      newPresta.gareAutreUic = this.selectedStation?.codeUic || journey.codeUic
      return newPresta
    },
    searchAccount (target) {
      if (!this.$perms.isUserAllowed('account.search')) { return }
      let keys = this._.keys(this.account)
      let sendReq = false
      let params = {}
      for (let i in keys) {
        if (!this._.isEmpty(this.account[keys[i]])) {
          params[keys[i]] = this.account[keys[i]]
          sendReq = true
        }
      }
      if (sendReq && target.target._value && target.target._value.length >= 3) {
        return this.$backendConnector.searchAccounts({ searchParams: params })
          .then(results => {
            if (results.length > 0) {
              this.accountSearch = true
              this.accounts = results
            }
          })
          .catch(err => console.log(err))
      } else {
        this.accountSearch = false
      }
    },
    chooseAccount (val) {
      this.account = this._.cloneDeep(val)
      this.onChangeType(val.typeAssist)
      this.account.specAssist = val.specAssist
      this.account.detailAssist = val.detailAssist
      this.account.specAssistSecondary = val.specAssistSecondary
      this.onChangeSpecSecondary(this.account.specAssistSecondary)
      this.account.detailAssistSecondary = val.detailAssistSecondary
      this.accountSearch = false
    },
    onChangeType (val) {
      this.specAssist = assist.getSpecAssist(val)
      this.detailAssist = assist.getDetailAssist(val)
      this.account.specAssist = null
      this.account.detailAssist = null
      this.account.specAssistSecondary = null
      this.account.detailAssistSecondary = null
    },
    onChangeSpecSecondary (val) {
      if (val === '' || val === null) {
        this.detailAssistSecondary = null
        this.account.detailAssistSecondary = null
      } else {
        this.detailAssistSecondary = assist.getDetailAssistSecondary(val)
        if (!this.detailAssistSecondary.map(d => d.acronym).includes(this.account.detailAssistSecondary)) {
          this.account.detailAssistSecondary = null
        }
      }
    },
    fetchPivDataTrainNumber () {
      let currentTrainNumber = this.trajet.trainNumber
      if (currentTrainNumber && currentTrainNumber !== this.pivSearchTrainNumber && (currentTrainNumber.length > 3)) {
        this.isLoadingPiv = true
        this.pivSearchTrainNumber = currentTrainNumber
        pivService.fetchPivDataByTrainNumber(currentTrainNumber, (new Date()), this.station.codeUic)
          .then(pivData => {
            if (this.pivSearchTrainNumber === currentTrainNumber) {
              this.isLoadingPiv = false
              if (pivData.passages.length > 0) {
                this.$toastService.showToast(this.$t('prestation.trajet.datafoundinpiv'), null, 'info')
                let passage = pivData.passages[0]
                this.trajet.transporter = passage.transporter
              }
            }
          })
          .catch(err => {
            this.isLoadingPiv = false
            this.$toastService.showToast(err.message, null, 'warning')
          })
      }
    },
    fetchPivDataMissionCode () {
      let currentMissionCode = this.trajet.missionCode
      if (currentMissionCode && currentMissionCode !== this.pivSearchMissionCode && (currentMissionCode.length > 2)) { // search by code mission
        this.isLoadingPiv = true
        this.pivSearchMissionCode = currentMissionCode
        pivService.fetchPivDataByMissionCode(currentMissionCode, (new Date()), this.station.codeUic)
          .then(pivData => {
            if (this.pivSearchMissionCode === currentMissionCode) {
              this.isLoadingPiv = false
              if (pivData.passages.length > 0) {
                this.$toastService.showToast(this.$t('prestation.trajet.datafoundinpivmissioncode'), this.$el, 'info')
                let passage = pivData.passages[0]
                this.trajet.transporter = passage.transporter
                this.trajet.trainNumber = passage.trainNumber
              }
            }
          })
          .catch(err => {
            this.isLoadingPiv = false
            this.$toastService.showToast(err.message, this.$el, 'warning')
          })
      }
    },
    setOnBoardData() {
      this.trajet.trainNumber = this.$store.getters.trainNumber
      this.trajet.transporter = this.$store.getters.onBoardTransporter
    },
    selectStation (valSelected) {
      if (valSelected !== null) {
        if (this.station.codeUic === this.selectedStation.codeUic) {
          this.$toastService.showToast(this.$t('prestation.error.sameDepartureAndArrival'), null, 'error');
          this.selectedStation = null;
        } else {
          this.selectedStation = valSelected;
        }
      } else {
        this.selectedStation = null;
      }
    },
    async asyncStationSearch(query) {
      if (!query.length) {
        this.stationsSearchResult = []
        return
      }
      if (this.isOnBoard) {
        this.stationsSearchResult = filterStations(this.$store.getters.possibleStations, query)
        return
      }
      try {
        const results = await this.$backendConnector.searchStations(query)
        this.stationsSearchResult = results
      } catch (error) {
        console.log(error)
      }
    },
    isOnlyDeparturePrestation() { // Displays the arrival input
      return this.station.mention !== 'A' && this.station.mention !== 'AC'
    }
  },
  validators: {
    'trajet.trainNumber': function (value) {
      if (!this.trajet.missionCode) {
        return Validator.value(value).required()
      }
    },
    'trajet.missionCode': function (value) {
      if (!this.trajet.trainNumber) {
        return Validator.value(value).required().lengthBetween(3, 15)
      }
    },
    'trajet.transporter': function (value) {
      return Validator.value(value).required()
    },
    'account.civility': function (value) {
      return Validator.value(value).required()
    },
    'account.firstname': function (value) {
      return Validator.value(value).required()
    },
    'account.lastname': function (value) {
      return Validator.value(value).required()
    },
    'account.phone': function (value) {
      return Validator.value(value).regex('^[0-9]{10,14}$')
    },
    'station.mention': function (value) {
      return Validator.value(value).required()
    },
    'station.reason': function (value) {
      return Validator.value(value).required()
    },
    'station.commentary': function (value) {
      if (this.mandatoryCommentary) {
        return Validator.value(value).required()
      }
    }
  },
  components: { AccountSearchCards, Multiselect }
}
</script>
